$primary: #ff3434;
$secondary: #fd9025;
$black: #000000;
$greener: #98eb1c;
$white: #ffffff;
$dropdown: #85C8DD;
$redish-gray: #000000;

$brown: #FCC096;
$twitter: #76CCFF;
$light-brown: #FCE0A2;
$pink: #F27788;

$screen-lg: 1200px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-xs: 480px;